import { AllThemes, MediaKitStat } from "@withjuly/fabric";

export const formatNumber = (followerCount: number): string => {
	if (followerCount < 10_000) {
		return followerCount.toLocaleString("en-US");
	} else if (followerCount < 100_000) {
		const thousands = Math.round(followerCount / 100) / 10;
		return `${thousands}k`;
	} else if (Math.round(followerCount / 1000) * 1000 < 1_000_000) {
		// In the upper hundreds of thousands, avoid showing a decimal (so 250k
		// instead of 250.5k)
		const thousands = Math.round(followerCount / 1000);
		return `${thousands}k`;
	} else if (Math.round(followerCount / 100_000) * 100000 < 1_000_000_000) {
		const millions = Math.round(followerCount / 100_000) / 10;
		return `${millions}m`;
	} else {
		const billions = Math.round(followerCount / 100_000_000) / 10;
		return `${billions}b`;
	}
};

export const hasInsufficientData = (stat: MediaKitStat) => {
	if (stat.type === "pie" || stat.type === "bar") {
		const hasAnyEntry = Object.values(stat.data).length > 0;
		if (!hasAnyEntry) {
			return true;
		}

		const hasNonZeroEntry = Object.values(stat.data).some((v) => v > 0);
		if (!hasNonZeroEntry) {
			return true;
		}
	}

	return false;
};

export const getThemeColorClassName = (theme: AllThemes) => {
	switch (theme) {
		case "july":
			return "bg-brand";
		case "green":
			return "bg-green-6";
		case "red":
			return "bg-red-6";
		case "yellow":
			return "bg-yellow-6";
		case "pink":
			return "bg-pink-6";
		case "purple":
			return "bg-purple-6";
		case "black":
			return "bg-surface-primary";
		default:
			return "bg-brand";
	}
};
