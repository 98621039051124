import {
	ConnectableAccountProvider,
	FACEBOOK,
	INSTAGRAM,
	Platform,
	TIKTOK,
	TWITCH,
	YOUTUBE,
	nonNull,
} from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import {
	FacebookLogo,
	InstagramLogo,
	TiktokLogo,
	TwitchLogo,
	YoutubeLogo,
} from "@withjuly/julycons";
import { Plus, Trash, X } from "@withjuly/julycons/bold";
import { Warning } from "@withjuly/julycons/fill";
import { Button, IconButton } from "@withjuly/solisv2";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useCallback, useMemo, useState } from "react";
import { formatNumber } from "~/components/Mediakits/utils";
import { useCreator } from "~/utils/context/creator";
import { useConnectAccount } from "~/utils/hooks/oauth";

export const FacebookAccounts = () => {
	const { creatorProfile } = useCreator();

	const facebookProfiles = creatorProfile?.connectedAccounts
		.filter((p) => p.platformProfile?.platform === Platform.FACEBOOK)
		.map((p) => p.platformProfile)
		.filter(nonNull);
	const hasFacebook = (facebookProfiles?.length ?? 0) > 0;

	const {
		onConnect,
		onDisconnect: onDisconnectFacebook,
		isConnected: isFacebookConnected,
		isLoading: isFacebookLoading,
	} = useConnectAccount(FACEBOOK, undefined, undefined, "facebook");

	const onRemove = (uuid: string) => {
		if (isFacebookConnected) {
			onDisconnectFacebook(uuid, ConnectableAccountProvider.FACEBOOKPAGE);
		}
	};

	const isLoading = isFacebookLoading;

	return (
		<div className={cx("flex flex-col px-4 pt-4")}>
			<div className="flex items-center justify-between pb-4">
				<p className="text-button-md flex items-center gap-3">
					<FacebookLogo className="h-5 w-5" /> Facebook
				</p>

				<Button
					variant="secondary"
					size="sm"
					onClick={onConnect}
					isLoading={isLoading}
					leadingIcon={Plus}
				>
					Add account
				</Button>
			</div>
			<div className="flex flex-col">
				{hasFacebook
					? facebookProfiles?.map((profile) => (
							<AccountBlock
								key={profile.uuid}
								uuid={profile.uuid}
								size={profile.size}
								username={profile.username}
								onDisconnect={onRemove}
								hasIssue={profile.hasIssue}
							/>
						))
					: null}
			</div>
		</div>
	);
};

export const InstagramAccounts = () => {
	const { creatorProfile } = useCreator();

	const instagramProfiles = creatorProfile?.connectedAccounts
		.filter((p) => p.platformProfile?.platform === Platform.INSTAGRAM)
		.map((p) => p.platformProfile)
		.filter(nonNull);
	const hasInstagram = (instagramProfiles?.length ?? 0) > 0;

	const profileHasIssue = useMemo(() => {
		return instagramProfiles?.some((profile) => profile.hasIssue) ?? false;
	}, [instagramProfiles]);

	const {
		onConnect,
		onDisconnect: onDisconnectFacebook,
		isConnected: isFacebookConnected,
		isLoading: isFacebookLoading,
	} = useConnectAccount(FACEBOOK);
	const {
		onDisconnect: onDisconnectInstagram,
		isConnected: isInstagramConnected,
		isLoading: isInstagramLoading,
	} = useConnectAccount(INSTAGRAM);

	const onDisconnect = useCallback(
		async (uuid: string) => {
			if (isFacebookConnected) {
				await onDisconnectFacebook(uuid);
			} else if (isInstagramConnected) {
				await onDisconnectInstagram(uuid);
			}
		},
		[
			isFacebookConnected,
			isInstagramConnected,
			onDisconnectFacebook,
			onDisconnectInstagram,
		],
	);

	const isLoading = isFacebookLoading || isInstagramLoading;

	return (
		<div
			className={cx(
				"flex flex-col px-4 pt-4",
				profileHasIssue && "bg-yellow-alpha-2",
			)}
		>
			<div className="flex items-center justify-between pb-4">
				<p className="text-button-md flex items-center gap-3">
					<InstagramLogo className="h-5 w-5" /> Instagram
				</p>

				<Button
					variant="secondary"
					size="sm"
					onClick={onConnect}
					isLoading={isLoading}
					leadingIcon={Plus}
					disabled={false}
				>
					Add account
				</Button>
			</div>
			<div className="flex flex-col">
				{hasInstagram
					? instagramProfiles?.map((profile) => (
							<AccountBlock
								key={profile.uuid}
								uuid={profile.uuid}
								size={profile.size}
								username={profile.username}
								onDisconnect={onDisconnect}
								hasIssue={false} // TODO: REMOVE
							/>
						))
					: null}
			</div>
		</div>
	);
};

export const TikTokAccounts = () => {
	const { creatorProfile } = useCreator();

	const tikTokProfiles = useMemo(
		() =>
			creatorProfile?.connectedAccounts
				.filter((p) => p.platformProfile?.platform === Platform.TIKTOK)
				.map((p) => p.platformProfile)
				.filter(nonNull) ?? [],
		[creatorProfile?.connectedAccounts],
	);
	const hasTikTok = (tikTokProfiles?.length ?? 0) > 0;

	const hasIssue = useMemo(
		() => tikTokProfiles.some((p) => p.hasIssue),
		[tikTokProfiles],
	);

	const { onConnect, isLoading, onDisconnect } = useConnectAccount(TIKTOK);

	return (
		<div
			className={cx("flex flex-col px-4 pt-4", hasIssue && "bg-yellow-alpha-2")}
		>
			<div className="flex items-center justify-between pb-4">
				<p className="text-button-md flex items-center gap-3">
					<TiktokLogo className="h-5 w-5" /> TikTok
				</p>

				<Button
					variant="secondary"
					size="sm"
					onClick={onConnect}
					isLoading={isLoading}
					leadingIcon={Plus}
				>
					Add account
				</Button>
			</div>

			<div className="flex flex-col gap-2">
				{hasTikTok
					? tikTokProfiles.map((profile) => (
							<AccountBlock
								key={profile.uuid}
								uuid={profile.uuid}
								size={profile.size}
								username={profile.username}
								onDisconnect={onDisconnect}
								hasIssue={hasIssue}
							/>
						))
					: null}
			</div>
		</div>
	);
};

export const YouTubeAccounts = () => {
	const { creatorProfile } = useCreator();

	const youTubeProfiles = useMemo(
		() =>
			creatorProfile?.connectedAccounts
				.filter((p) => p.platformProfile?.platform === Platform.YOUTUBE)
				.map((p) => p.platformProfile)
				.filter(nonNull) ?? [],
		[creatorProfile?.connectedAccounts],
	);
	const hasYouTube = (youTubeProfiles?.length ?? 0) > 0;
	const platformHasIssue = useMemo(() => {
		return youTubeProfiles.some((p) => p.hasIssue) ?? false;
	}, [youTubeProfiles]);

	const { onConnect, isLoading, onDisconnect } = useConnectAccount(YOUTUBE);

	return (
		<div
			className={cx(
				"flex flex-col px-4 pt-4",
				platformHasIssue && "bg-yellow-alpha-2",
			)}
		>
			<div className="flex items-center justify-between pb-4">
				<p className="text-button-md flex items-center gap-3">
					<YoutubeLogo className="h-5 w-5" /> YouTube
				</p>

				<Button
					variant="secondary"
					size="sm"
					onClick={onConnect}
					isLoading={isLoading}
					leadingIcon={Plus}
				>
					Add account
				</Button>
			</div>

			<div className="flex flex-col gap-2">
				{hasYouTube
					? youTubeProfiles.map((profile) => (
							<AccountBlock
								key={profile.uuid}
								uuid={profile.uuid}
								size={profile.size}
								username={profile.username}
								onDisconnect={onDisconnect}
								hasIssue={platformHasIssue}
							/>
						))
					: null}
			</div>
		</div>
	);
};

interface AccountBlockProps {
	uuid: string;
	username: string;
	size: number | undefined;
	onDisconnect: (uuid: string) => void;
	hasIssue?: boolean;
}
export const AccountBlock: React.FC<AccountBlockProps> = ({
	uuid,
	username,
	size,
	onDisconnect,
	hasIssue,
}) => {
	const [removeOptionsVisible, setRemoveOptionsVisible] = useState(false);

	return (
		<div className="flex h-12 items-center justify-between pb-4" key={uuid}>
			<div className="flex items-center justify-center gap-3">
				{hasIssue ? <Warning className="text-yellow-7 h-6 w-6" /> : null}
				<div className="flex flex-col">
					<p className="font-repro text-paragraph-sm truncate">@{username}</p>
					{size && !hasIssue ? (
						<p className="text-paragraph-xs font-repro text-text-secondary">
							{formatNumber(size)} followers
						</p>
					) : null}

					{hasIssue ? (
						<p className="text-yellow-7 text-paragraph-xs font-repro">
							Account disconnected
						</p>
					) : null}
				</div>
			</div>
			{removeOptionsVisible ? (
				<div className="flex items-center justify-center gap-3">
					<Button
						variant="danger"
						size="sm"
						leadingIcon={Trash}
						onClick={() => onDisconnect(uuid)}
					>
						Remove
					</Button>
					<IconButton
						size="sm"
						variant="secondary"
						onClick={() => setRemoveOptionsVisible(false)}
						icon={X}
					/>
				</div>
			) : hasIssue ? (
				<Button size="sm" onClick={() => onDisconnect(uuid)}>
					Reconnect
				</Button>
			) : (
				<IconButton
					size="sm"
					variant="secondary"
					onClick={() => setRemoveOptionsVisible(true)}
					icon={Trash}
				/>
			)}
		</div>
	);
};

export const TwitchAccounts = () => {
	const isTwitchEnabled = useFeatureFlagEnabled("twitch");
	const { creatorProfile } = useCreator();

	const twitchProfiles =
		creatorProfile?.connectedAccounts
			.filter((p) => p.platformProfile?.platform === Platform.TWITCH)
			.map((p) => p.platformProfile)
			.filter(nonNull) ?? [];
	const hasTwitch = (twitchProfiles?.length ?? 0) > 0;

	const { onConnect, isLoading, onDisconnect } = useConnectAccount(TWITCH);

	if (!isTwitchEnabled) {
		return null;
	}

	return (
		<div className={cx("flex flex-col px-4 pt-4")}>
			<div className="flex items-center justify-between pb-4">
				<p className="text-button-md flex items-center gap-3">
					<TwitchLogo className="h-5 w-5" /> Twitch
				</p>

				<Button
					variant="secondary"
					size="sm"
					onClick={onConnect}
					isLoading={isLoading}
					leadingIcon={Plus}
				>
					Add account
				</Button>
			</div>
			<div className="flex flex-col">
				{hasTwitch
					? twitchProfiles?.map((profile) => (
							<AccountBlock
								key={profile.uuid}
								uuid={profile.uuid}
								size={profile.size}
								username={profile.username}
								onDisconnect={onDisconnect}
								hasIssue={profile.hasIssue}
							/>
						))
					: null}
			</div>
		</div>
	);
};
